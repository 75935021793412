import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /.js$/)
application.load(definitionsFromContext(context))

import 'flowbite';

//alert("Hello Application")

// document.addEventListener("DOMContentLoaded", function () {
//     const options = {
//         slidesPerView: 2,
//         spaceBetween: 16,
//         autoplay: {
//             delay: 2500,
//             disableOnInteraction: false,
//         },
//         breakpoints: {
//             640: {
//                 slidesPerView: 3,
//             },
//             768: {
//                 slidesPerView: 4,
//             },
//             1024: {
//                 slidesPerView: 6,
//             },
//         },
//     }
//
//     const swiper = new Swiper("#slider-merits", options);
// });